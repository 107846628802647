import React from 'react'
import { graphql } from "gatsby"

import Layout from '../components/layout'

const NumericalPage = ({data}) => {
  const html = data.wpNumericals.content
  return (
    <Layout>
      <h1 style={{textAlign: "justify"}}>
        {data.wpNumericals.acfnumerical.numericalStatement}
      </h1>

      <h2>{data.wpNumericals.acfnumerical.book.acfbook.bookTitle}</h2>
      <h2>{data.wpNumericals.acfnumerical.chapter.acfchapter.chapterTitle}</h2>
      <div dangerouslySetInnerHTML={{ __html: html }} />

    </Layout>
  )
}

export default NumericalPage



export const query = graphql`
query($slug: String!){
    wpNumericals(slug: {eq: $slug}) {
      content
      acfnumerical {
        numericalStatement
        book {
          ... on WpBook {
            id
            slug
            acfbook {
              bookTitle
            }
          }
        }
        chapter {
          ... on WpChapter {
            id
            acfchapter {
              chapterTitle
            }
          }
        }
      }
    }
  }
`
